// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'
import 'antd/dist/antd.css';
import WebRoutes from './WebRoutes'
import HomeScreen from '../../components/src/HomeScreen';
import './App.css';
import { ToastContainer } from "react-toastify";
import { CometChat } from '@cometchat-pro/chat';

const COMETCHAT_CONSTANTS = {
  APP_ID: "255719c8f6ab8cca",
  REGION: "IN",
  AUTH_KEY: "196ea1022670c085e4fe42546c10c53b5520cea0"
};

//create the builder
let UIKitSettings = new CometChat.AppSettingsBuilder()
.subscribePresenceForAllUsers()
  .setRegion(COMETCHAT_CONSTANTS.REGION)
  .autoEstablishSocketConnection(true)
  .build()

//Initialize CometChat UIKit
CometChat.init(COMETCHAT_CONSTANTS.APP_ID, UIKitSettings)?.then(() => {
    console.log("Initialization completed successfully");
}).catch(console.log);

if (!HomeScreen.instance) {
  const defaultProps = {
    navigation: null,
    id: 'HomeScreen'
  };
  const homeScreen = new HomeScreen(defaultProps);
}

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {
    return (
      <div>
        <WebRoutes />
        <ToastContainer autoClose={2000}/>
      </div>
    );
  }
}

export default App;

