import React from 'react';
import { Redirect, Route } from "react-router-dom";

export const SellerPrivateRoutes = (props: any) => {
    let auth = { token: localStorage.getItem("authToken") ? true : false };
    if(localStorage.getItem("role")){
        let role = JSON.parse(localStorage.getItem("role") || "").attributes.role.name
        return auth.token && role === "seller" ? <Route {...props} /> : <Redirect to="/login" />;
    }else{
        return  <Redirect to="/login" />;
    }
};

export default SellerPrivateRoutes;