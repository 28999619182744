//@ts-nocheck
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import Loader from '../../components/src/Loader.web';
import { BuyerPrivateRoutes } from './BuyerPrivateRoutes';
import { SellerPrivateRoutes } from './SellerPrivateRoutes';

//* Routes List start
const LandingPage = lazy(() =>
    import('../../blocks/landingpage/src/LandingPage.web')
);
const EmailAccountRegistration = lazy(() =>
    import('../../blocks/email-account-registration/src/EmailAccountRegistration.web')
)
const EmailAccountLoginBlock = lazy(() =>
    import('../../blocks/email-account-login/src/EmailAccountLogin.web')
)
const ForgotPassword = lazy(() =>
    import('../../blocks/forgot-password/src/ForgotPassword')
)
const TermsAndConditions3 = lazy(() =>
    import('../../blocks/TermsAndConditions3/src/TermsAndConditions3.web')
)
const Dashboard = lazy(() =>
    import('../../blocks/dashboard/src/Dashboard.web')
)
const Academy = lazy(() =>
    import('../../blocks/BlogPostsManagement/src/Academy.web')
)
const AcademyDetails = lazy(() =>
    import('../../blocks/BlogPostsManagement/src/AcademyDetails.web')
)
const VideoDetails = lazy(() =>
    import('../../blocks/VideoManagement/src/VideoDetails.web')
)
const Seller = lazy(() =>
    import('../../blocks/RolesPermissions/src/Seller.web')
)
const Categoriessubcategories = lazy(() =>
    import('../../blocks/categoriessubcategories/src/Categoriessubcategories.web')
)
const Catalogue = lazy(() =>
    import('../../blocks/catalogue/src/Products.web')
)
const ProductVariantList = lazy(() =>
    import('../../blocks/ordercreation2/src/ProductVariantList.web')
)
const BuyerOrders = lazy(() =>
    import('../../blocks/ordermanagement/src/BuyerOrders.web')
)
const UserProfileBasic = lazy(() =>
    import('../../blocks/user-profile-basic/src/UserProfileBasicBlock.web')
)
const EditUserProfileBasic = lazy(() =>
    import('../../blocks/user-profile-basic/src/EditUserProfileBasicBlock.web')
)
const ContactForm = lazy(() =>
    import('../../blocks/contactus/src/ContactForm.web')
)
const ManageAddresse = lazy(() =>
    import('../../blocks/addressmanagement/src/ManageAddresse.web')
)
const ProductVariantDetails = lazy(() =>
    import('../../blocks/ProductDescription/src/BuyerProductDetails.web')
)
const BuyerOrderDetails = lazy(() =>
    import('../../blocks/ordermanagement/src/BuyerOrderDetails.web')
)
const BuyerSaveForLater = lazy(() =>
    import('../../blocks/ordercreation2/src/BuyerSaveForLater.web')
)
const BuyerPayout = lazy(() =>
    import('../../blocks/CfPriceTrends/src/BuyerPayout.web')
)
const BuyerLedger = lazy(() =>
    import('../../blocks/CfPriceTrends/src/BuyerLedger.web')
)
const BuyerNotification = lazy(() =>
    import('../../blocks/notifications/src/BuyerNotification.web')
)
const BuyerCometChat = lazy(() =>
    import('../../blocks/chat/src/BuyerCometChat.web')
)
// /* Routes List End /

function WebRoutes() {
    return (
        <Router>
            <Suspense fallback={<Loader loading />}>
                <Switch>
                    <Route
                        path="/"
                        exact
                        render={props => <LandingPage {...props} />}
                    />
                    <Route
                        path="/signup"
                        exact
                        render={props => <EmailAccountRegistration {...props} />}
                    />
                    <Route
                        path="/login"
                        exact
                        render={props => <EmailAccountLoginBlock {...props} />}
                    />
                    <Route
                        path="/forgot-password"
                        exact
                        render={props => <ForgotPassword {...props} />}
                    />
                    <Route
                        path="/about-us"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/terms-and-conditions"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/privacy-policy"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/return-and-cancellation-policy"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/refund-policy"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/shipping-policy"
                        exact
                        render={props => <TermsAndConditions3 {...props} />}
                    />
                    <Route
                        path="/video-details/:slug"
                        exact
                        render={props => <VideoDetails {...props} slug={props.match.params.slug} />}
                    />
                    <Route
                        path="/academy/:slug?"
                        exact
                        render={props => <Academy {...props} slug={props.match.params.slug} />}
                    />
                    <Route
                        path="/academy-details/:slug"
                        exact
                        render={props => <AcademyDetails {...props} slug={props.match.params.slug} />}
                    />
                    <Route
                        path="/contact"
                        exact
                        render={props => <ContactForm {...props} />}
                    />
                    <Route
                        path="/landing/AboutUs"
                        exact
                        render={props => <LandingPage {...props} />}
                    />
                    <Route
                        path="/profile"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <UserProfileBasic {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/edit-profile"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <EditUserProfileBasic {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    {/* Buyer Private Routes */}
                    <Route
                        path="/buyer/dashboard"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <Dashboard {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/category/:type/:slug?"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <Categoriessubcategories {...props} slug={props.match.params.slug} type={props.match.params.type} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/catalogue/:type?/:slug?"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <Catalogue {...props}
                                    slug={props.match.params.slug}
                                    type={props.match.params.type}
                                    category={props.match.params.category}
                                    subCategory={props.match.params.subCategory}
                                />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/productVariantsList/:slug/:id/:saveLaterId?"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <ProductVariantList {...props}
                                    slug={props.match.params.slug}
                                    productId={props.match.params.id}
                                    saveLaterId={props.match.params.saveLaterId}
                                    category={props.match.params.category}
                                    subCategory={props.match.params.subCategory}
                                />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/ProductVariantDetails/:slug/:id?"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <ProductVariantDetails {...props}
                                    slug={props.match.params.slug}
                                    productId={props.match.params.id}
                                    category={props.match.params.category}
                                    subCategory={props.match.params.subCategory}
                                />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/addresses"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <ManageAddresse {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/:slug/orders"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerOrders {...props} slug={props.match.params.slug} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/:slug/orderDetails/:orderId"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerOrderDetails {...props}
                                    slug={props.match.params.slug}
                                    orderId={props.match.params.orderId}
                                />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/:slug/saveForLater"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerSaveForLater
                                    {...props}
                                    slug={props.match.params.slug}
                                />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/payout"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerPayout {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/ledger"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerLedger {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/notification"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerNotification {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    <Route
                        path="/buyer/chat"
                        exact
                        render={props =>
                            <BuyerPrivateRoutes>
                                <BuyerCometChat {...props} />
                            </BuyerPrivateRoutes>
                        }
                    />
                    {/* Seller Private Routes */}
                    <Route
                        path="/seller/:page/:id?"
                        exact
                        render={props =>
                            <SellerPrivateRoutes>
                                <Seller {...props} page={props.match.params.page} productId={props.match.params.id} />
                            </SellerPrivateRoutes>
                        }
                    />
                </Switch>
            </Suspense>
        </Router>
    )
}
//@ts-ignore
export default withRouter(WebRoutes);
